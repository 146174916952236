"use client";

import { useTranslation } from "./hooks/use-translation.hook";
import type { TranslationProps } from "./translation.types";

/**
 * You can pass translations as either individual props or a "translation" model.
 * Ex. <Translation en="Hello" es="Hola" ... /> or <Translation model={{ en: "Hello", es: "Hola" ... }} />
 */
export function Translation<T>(props: TranslationProps) {
  const translation = useTranslation<T>(props);
  // eslint-disable-next-line react/jsx-no-useless-fragment -- We have to return valid JSX
  return <>{translation}</>;
}
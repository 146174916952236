import type { LogoProps } from "./logo-props.types";
export default function WatchMediaLogo({
  className,
  primaryClassName,
  secondaryClassName
}: LogoProps) {
  return <svg aria-hidden="true" className={className} height="50" viewBox="0 0 340.95 35.85" data-sentry-element="svg" data-sentry-component="WatchMediaLogo" data-sentry-source-file="WatchMediaLogo.component.tsx">
      <path className={primaryClassName ?? "fill-brand-logo-primary"} d="M4.25 6.75C3.1 3.5 2.7 2.6 1.25 2.25L0 2V.6h16.15V2l-1.25.3c-1.1.3-1.65.8-1.65 1.9 0 1 .4 2.4.85 3.8l5.45 17.45h.25l4.7-14.4-1.55-5C22.15 3.6 21.5 2.7 20.1 2.3L18.95 2V.6H35.1V2l-1.8.35c-1 .25-1.55.9-1.55 2.05 0 .9.3 2.2.75 3.6l5.45 17.45h.25l5.05-17.5c.4-1.3.65-2.55.65-3.55 0-1.25-.6-1.85-1.8-2.1L40.5 2V.6h10.25V2l-1.15.25c-1.6.45-2.25 1.9-3.5 5.7l-8.6 27.3h-5.2L25.95 15h-.2l-6.7 20.25h-5.3l-9.5-28.5ZM45.3 33.85l1.25-.35c1.5-.45 1.95-.95 3.4-5.05L60 .6h5l10.35 28c1.35 3.7 1.9 4.55 3.35 5l1.05.25v1.4H63.1v-1.4l1.4-.35c1.3-.35 1.8-.95 1.8-2 0-.85-.45-2.2-.95-3.65l-1.2-3.55h-10l-1.2 3.6c-.45 1.4-.75 2.55-.75 3.4 0 1.4.6 1.8 2.15 2.2l1.4.35v1.4H45.3v-1.4Zm9.6-11.75h8.5L59.2 9.9h-.25L54.9 22.1ZM83.55 33.85l1.85-.35c1.75-.4 2.05-.6 2.05-3.65V2.8h-.5c-3 0-3.7.35-5.1 2.3-1.2 1.7-2 3.6-3.45 7.05h-1.6L77.2.6h29.6l.4 11.55h-1.55c-1.45-3.45-2.3-5.35-3.5-7.05-1.45-2.05-2-2.3-5.1-2.3h-.5v27.05c0 3.05.25 3.25 2.05 3.65l1.85.35v1.4h-16.9v-1.4ZM109.2 18.2c0-12.9 8.55-18.2 18.2-18.2 3.85 0 7.35.55 9.5 1.15l.55 10.35h-1.55c-1.45-3.1-2.85-5.4-4-7.05-1.3-1.7-2.6-2.35-5.05-2.35-5.85 0-8.05 4.25-8.05 15.1 0 13 2.55 16.55 8.05 16.55 2.9 0 4.25-.8 5.7-2.8 1.35-1.9 2.5-4.05 3.8-7.15h1.45l-.55 10c-2.35 1-6.1 2.05-10.95 2.05-9.9 0-17.1-5.8-17.1-17.65ZM139.9 33.85l1.7-.35c1.5-.35 1.95-.6 1.95-3.65V6c0-3.05-.45-3.3-1.95-3.65L139.9 2V.6h16.35V2l-1.65.35c-1.6.45-1.95.6-1.95 3.65v10.4h11.2V6c0-3.05-.35-3.2-1.95-3.65L160.25 2V.6h16.35V2l-1.65.35c-1.55.35-2 .6-2 3.65v23.85c0 3.05.45 3.25 2 3.65l1.65.35v1.4h-16.35v-1.4l1.65-.35c1.6-.45 1.95-.6 1.95-3.65V18.7h-11.2v11.15c0 3.05.35 3.2 1.95 3.65l1.65.35v1.4H139.9v-1.4Z" data-sentry-element="path" data-sentry-source-file="WatchMediaLogo.component.tsx" />
      <path className={secondaryClassName ?? "fill-brand-logo-secondary"} d="m178.3 33.85 1.5-.35c1.6-.35 2.15-.6 2.15-4.15V6c0-3.05-.45-3.3-1.95-3.65L178.3 2V.6h14.05l7.1 20.85h.15L206.55.6h14V2l-1.7.35c-1.55.4-2 .6-2 3.65v23.85c0 3.05.45 3.25 2 3.65l1.7.35v1.4h-16.4v-1.4l1.65-.35c1.6-.4 1.95-.6 1.95-3.65V5.3h-.2l-9.6 29.2h-3.25l-9.95-29.2h-.2v23.8c0 3.75.3 4 2.25 4.4l1.55.35v1.4H178.3v-1.4ZM222.2 33.85l1.7-.35c1.5-.4 1.95-.6 1.95-3.65V6c0-3.05-.45-3.3-1.95-3.65L222.2 2V.6h27.45l.5 10.1h-1.55c-1.25-2.8-2.1-4.25-2.85-5.35-1.6-2.35-2.7-2.55-6.2-2.55-2.6 0-3.85.3-4.6.5v12.9h1.35c3.55 0 4.35-.4 4.95-1.5.7-1.25 1.1-2.25 1.55-3.85h1.5V23.9h-1.5c-.45-1.55-.85-2.6-1.55-4.05-.6-1.25-1.25-1.5-4.95-1.5h-1.35v9.7c0 4.5.15 5 5.05 5 4.05 0 4.85-.5 6.55-3 .9-1.35 1.9-3.5 3.2-6.7h1.6l-.7 11.9H222.2v-1.4ZM253.1 33.85l1.7-.35c1.5-.4 1.95-.6 1.95-3.65V6c0-3.05-.45-3.3-1.95-3.65L253.1 2V.6h16.95c10.45 0 17.35 4.95 17.35 16.7s-6.8 17.95-17.6 17.95h-16.7v-1.4Zm24.8-15.35c0-11.8-2.8-15.7-8.7-15.7-1.55 0-2.85.2-3.35.45V28.5c0 3.95.6 4.55 3.45 4.55 6.15 0 8.6-4.8 8.6-14.55ZM289.1 33.85l1.85-.35c1.55-.35 1.95-.6 1.95-3.65V6c0-3.05-.4-3.3-1.95-3.65L289.1 2V.6h16.75V2l-1.85.35c-1.6.35-2 .6-2 3.65v23.85c0 3.05.4 3.3 2 3.65l1.85.35v1.4H289.1v-1.4ZM306.5 33.85l1.25-.35c1.5-.45 1.95-.95 3.4-5.05L321.2.6h5l10.35 28c1.35 3.7 1.9 4.55 3.35 5l1.05.25v1.4H324.3v-1.4l1.4-.35c1.3-.35 1.8-.95 1.8-2 0-.85-.45-2.2-.95-3.65l-1.2-3.55h-10l-1.2 3.6c-.45 1.4-.75 2.55-.75 3.4 0 1.4.6 1.8 2.15 2.2l1.4.35v1.4H306.5v-1.4Zm9.6-11.75h8.5l-4.2-12.2h-.25l-4.05 12.2Z" data-sentry-element="path" data-sentry-source-file="WatchMediaLogo.component.tsx" />
    </svg>;
}